module.exports = {
  regex: {
    // 邮箱验证
    email(e) {
      const regex =
        /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
      return regex.test(e);
    },
    // 澳洲手机号
    phone(e) {
      const regex = /^0?4\d{8}$/;
      return regex.test(e);
    },
  },
  /**
   * 拼接公寓地址
   * 数据结构同vuex中apartment对象
   * @param apartment
   * @returns {string}
   */
  addressJoinStr(apartment) {
    return `${
      apartment.name || apartment.unitName || apartment.apartmentName
    } - ${apartment.street}, ${apartment.city}, ${apartment.province}, ${
      apartment.postcode || "Australia"
    }`;
  },
  dateVal(val) {
    if (!val) return "";
    // 判断是上午或者下午
    if (parseInt(val.substring(0, 2)) > 12) {
      return val + "pm";
    } else {
      return val + "am";
    }
  },
  // 格式化数字添加千位分隔符和$符号
  formatMoney(value) {
    if (!value) return "$0.00";
    value = (value + "").replace(/,/g, "");
    value = Number(value).toFixed(2); // 要求和接口统一保持四舍五入20220627
    const intPart = Math.trunc(value); // 获得整数部分
    const intPartFormat = intPart
      .toString()
      .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    let floatPart = "";
    const valueArray = value.toString().split("."); // 以小数点作为分隔符拆分为一个数组
    if (valueArray.length === 2) {
      // 有小数部分
      // floatPart = (valueArray[1] < 10 ? `${valueArray[1]}0` : valueArray[1]).toString() // 取得小数部分并保留两位小数
      const Part = valueArray[1].toString().substring(0, 2);
      floatPart = Part.length < 2 ? `${Part}0` : Part; // 取得小数部分并保留两位小数
      return "$" + intPartFormat + "." + floatPart;
    }
    return "$" + intPartFormat + ".00";
  },
};
