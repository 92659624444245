<template>
  <div id="app">
    <nav-bar class="nav-bar" v-show="navBarShow" />
    <div class="bottom-box">
      <side-bar class="side-bar" v-show="sideBarShow" />
      <div
        class="page-content-scroll g-scroll-hide"
        :style="{ height: pageHeight }"
      >
        <router-view :style="{ minHeight: pageHeight }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SideBar from "@/components/SideBar";
import NavBar from "@/components/NavBar";

export default {
  name: "app",
  components: { SideBar, NavBar },
  computed: {
    ...mapState("bar", ["sideBarShow", "navBarShow"]),
    pageHeight() {
      const n = this.navBarShow ? 55 : 0;
      return `calc(100vh - ${n}px)`;
    },
  },
  created() {
    this.storeCache();
  },
  methods: {
    // vuex缓存
    storeCache() {
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("store")) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem("store"))
          )
        );
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .nav-bar {
    flex: 0 0 auto;
    height: 55px;
  }

  .bottom-box {
    flex: 1;
    display: flex;

    .side-bar {
      flex: 0 0 auto;
    }

    .page-content-scroll {
      flex: 1;
      overflow: auto;
    }
  }
}

@media screen and (max-width: 980px) {
}

@media screen and (min-width: 980px) {
}
</style>
