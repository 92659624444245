<template>
  <div class="nav-bar" :class="{ 'side-hide': sideBarStatus === -1 }">
    <div class="nav-content">
      <div class="nav">
        <m-logo class="logo" width="100px" />
        <user />
      </div>

      <div v-if="sideBarStatus === 1" class="side">
        <el-tabs v-model="activePath">
          <el-tab-pane
            v-for="(item, index) in presentData"
            :key="index"
            :label="item.label"
            :name="item.path"
          />
        </el-tabs>
      </div>
      <div v-else v-show="sideBarStatus !== -1" class="side flex-column">
        <div class="page-name flex-1 flex-center">
          {{ pageName }}
        </div>
        <div class="progress-bar flex-row flex-0">
          <div
            class="items flex-0 flex-row"
            :class="{ show: itemsShow(index) }"
            v-for="(item, index) in presentData"
            :key="index"
          >
            <div class="line"></div>
            <div class="circle"></div>
            <div :class="{ line: true, 'hide-line': lineShow(index) }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/base/User";
import { mapGetters, mapState } from "vuex";
export default {
  name: "NavBar",
  components: { User },
  data() {
    return {
      activePath: "/profile/basicInfo",
    };
  },
  mounted(){
    console.log(this.activePath);
    console.log(this.presentData);
  },
  computed: {
    ...mapState("bar", ["sideBarStatus"]),
    ...mapGetters("bar", ["presentData"]),
    pageName() {
      try {
        return this.presentData[this.presentDataIndex].label;
      } catch {
        return "";
      }
    },
    lineShow() {
      return (index) => {
        if (this.presentData.length - 1 > index)
          return index.toString() === this.presentDataIndex;
      };
    },
    // 计算当前页的index
    presentDataIndex() {
      if (!this.presentData) return false;
      for (const i in this.presentData)
        if (this.presentData[i].path === this.$route.path) return i;
      return -1;
    },
    // 每个节点的样式
    itemsShow() {
      return (index) => this.presentDataIndex >= index;
    },
  },
  watch: {
    activePath(d, t) {
      if (d === t) return false;
      this.$router.replace(d);
    },
    $route:{
      handler(val){
        this.activePath=val.path;
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
