import Vue from "vue";
import VueRouter from "vue-router";

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("@/views/dashboard"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
  },
  {
    path: "/profile",
    name: "profile",
    redirect: "/profile/basicInfo",
  },
  {
    path: "/profile/basicInfo",
    name: "basicInfo",
    component: () => import("@/views/Profile/basicInfo"),
  },
  {
    path: "/profile/security",
    name: "Security",
    component: () => import("@/views/Profile/security"),
  },
  {
    path: "/profile/contact",
    name: "Contact details",
    component: () => import("@/views/Profile/contact"),
  },
  {
    path: "/profile/forget",
    name: "Forget PassWord",
    component: () => import("@/views/Profile/forget"),
  },
  {
    path: "/profile/email",
    name: "Email",
    component: () => import("@/views/Profile/email"),
  },
  {
    path: "/contactDetails",
    name: "Contact Details",
    component: () => import("@/views/contactDetails"),
  },
  {
    path: "/buildingAddress",
    name: "Building address",
    component: () => import("@/views/buildingAddress"),
  },
  {
    path: "/buildingInfo",
    name: "Building Information",
    component: () => import("@/views/buildingInfo"),
  },
  {
    path: "/buildingContacts",
    name: "Building Contacts",
    component: () => import("@/views/buildingContacts"),
  },
  {
    path: "/pay",
    name: "Payment",
    component: () => import("@/views/pay"),
  },
  {
    path: "/dateAndTime",
    name: "Date & Time",
    component: () => import("@/views/dateAndTime"),
  },
  {
    path: "/noticeBoard",
    name: "Notice Board",
    component: () => import("@/views/noticeBoard"),
  },
  {
    path: "/bookingRules",
    name: "Booking Rules",
    component: () => import("@/views/bookingRules"),
  },
  {
    path: "/myBooking",
    name: "My Booking",
    component: () => import("@/views/myBooking"),
  },
  {
    path: "/newBooking",
    name: "New Booking",
    component: () => import("@/views/newBooking"),
  },
  {
    path: "/editBooking",
    name: "Edit Booking",
    component: () => import("@/views/editBooking"),
  },
  {
    path: "/newBookingCommunalFacility",
    name: "New Booking-Communal Facility",
    component: () => import("@/views/newBookingCommunalFacility"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
  },
  {
    path: "/termsAndConditions",
    name: "Terms And Conditions",
    component: () => import("@/views/termsAndConditions"),
  },
  {
    path: "/privacyPolicy",
    name: "Privacy Policy",
    component: () => import("@/views/privacyPolicy"),
  },
  {
    path: "/termsConditions",
    name: "Terms Conditions",
    component: () => import("@/views/termsConditions"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
