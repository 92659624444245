import Vue from "vue";
import Logo from "@/components/base/Logo";

import Back from "@/components/base/Back";
import PageTitle from "@/components/base/PageTitle";
import ToDashboard from "@/components/base/ToDashboard";
import Dialog from "@/components/Dialog";

Vue.component("m-logo", Logo);
Vue.component("m-back", Back);
Vue.component("m-page-title", PageTitle);
Vue.component("m-to-dashboard", ToDashboard);
Vue.component("m-dialog", Dialog);
