<template>
  <el-button class="to-dashboard-btn" type="primary" @click="$router.push('/')">
    Back to dashboard
  </el-button>
</template>

<script>
export default {
  name: "to-dashboard",
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 980px) {
  .to-dashboard-btn {
    width: 100% !important;
  }
}
</style>
