<template>
  <el-image
    class="g-pointer"
    :src="image"
    fit="scale-down"
    :style="{ width, height }"
    @click="toHome"
  />
</template>

<script>
/**
 * @property : {String}  width 宽度
 * @property : {String}  height 高度
 * @property : {Boolean}  white 是否显示白色logo
 * @property : {Boolean}  unJump 取消跳转
 */
export default {
  name: "m-logo",
  props: {
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    white: {
      type: Boolean,
      default: false,
    },
    unJump: { type: Boolean, default: false },
  },
  computed: {
    image() {
      return this.white
        ? require("@/static/image/bar/logo_white.png")
        : require("@/static/image/bar/log.png");
    },
  },
  methods: {
    toHome() {
      if (this.unJump) return false;
      location.href = this.$api.homeLink;
    },
  },
};
</script>

<style scoped lang="scss"></style>
