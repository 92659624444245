import Vue from "vue";

// 格式化时间
Vue.filter("formatTimeAll", (e) => {
  return new Date(e).$formatTime("yyyy-mm-dd h:i:s");
});
Vue.filter("formatTimeDmy", (e) => {
  if (e) {
    e = e.replace(/-/g, "/");
    // var time = Date.parse(new Date(e)) / 1000;
    return new Date(e).$formatTime("dd/mm/yyyy");
  }
});
Vue.filter("formatTimeHis", (e) => {
  return new Date(e).$formatTime("h:i:s");
});

// 是否入住
Vue.filter("isMoveIn", (e) => {
  return e ? "Residing" : "Non-Residing";
});
