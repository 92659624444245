const debug = false;

// key: sideBarStatus val: sideBarData
const sideBarStatusArr = [
  "signup", // 注册流程
  "profile", // 个人信息
  "agreement", // 阅读协议
  "reserve", // 预约
  "addApartment", // 添加公寓
];

export default {
  namespaced: true,
  state: () => ({
    navBarShow: true,
    sideBarShow: true,
    // 侧边栏功能
    //  - -1: 无
    //  - 0: 注册流程
    sideBarStatus: -1,
    sideBarData: {
      // 注册流程
      signup: [
        { label: "Sign up", path: "/login", check: false },
        { label: "Additional details", path: "/contactDetails", check: false },
        { label: "Building address", path: "/buildingAddress", check: false },
      ],
      // 个人信息
      profile: [
        { label: "Basic information", path: "/profile/basicInfo", check: true },
        { label: "Contact details", path: "/profile/contact", check: true },
        { label: "Security", path: "/profile/security", check: true },
        { label: "Email", path: "/profile/email", check: true },
      ],
      // 阅读协议
      agreement: [
        { label: "Sign up", path: "/login", check: false },
        { label: "Contact details", path: "/contactDetails", check: false },
        { label: "Building address", path: "/buildingAddress", check: false },
        { label: "Booking Rules", path: "/bookingRules", check: false },
        { label: "Building Info", path: "/buildingInfo", check: false },
        { label: "Notice Board", path: "/noticeBoard", check: false },
      ],
      // 预约
      reserve: [
        { label: "Building address", path: "/buildingAddress", check: false },
        { label: "Date & Time", path: "/dateAndTime", check: false },
        { label: "Confirm", path: "/pay", check: false },
      ],
      // 添加公寓
      addApartment: [
        { label: "Building address", path: "/buildingAddress", check: false },
        { label: "Booking Rules", path: "/bookingRules", check: false },
        { label: "Building Info", path: "/buildingInfo", check: false },
        { label: "Notice Board", path: "/noticeBoard", check: false },
      ],
    },
  }),
  getters: {
    presentData(state) {
      return state.sideBarData[sideBarStatusArr[state.sideBarStatus]];
    },
  },
  mutations: {
    // 状态栏显示
    navBarShow(state, bool = true) {
      debug && console.log("navBarShow: ", bool);
      state.navBarShow = bool;
    },
    // 侧边栏显示
    sideBarShow(state, bool = true) {
      debug && console.log("sideBarShow: ", bool);
      state.sideBarShow = bool;
    },
    // 侧边栏状态变更
    setSideBarStatus(state, status = -1) {
      debug && console.log("setSideBarStatus: ", status);
      state.sideBarStatus = status;
    },
  },
  actions: {},
};
