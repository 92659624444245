const debug = false;
import { addressJoinStr } from "@/utils/tools";

export default {
  namespaced: true,
  state: () => ({
    room: {},
    apartment: {},
  }),
  getters: {
    addressStr(state) {
      return addressJoinStr(state.apartment);
      // return `${a.name} - ${a.street}, ${a.region}, ${a.city}, ${a.province}, ${a.postcode}`;
    },
  },
  mutations: {
    setRoom(state, obj = {}) {
      debug && console.log("setRoom");
      debug && console.log(obj);
      state.room = obj;
    },
    setApartment(state, obj = {}) {
      debug && console.log("setApartment");
      debug && console.log(obj);
      state.apartment = obj;
    },
  },
  actions: {},
};
