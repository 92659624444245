<template>
  <div class="page-title">
    <h2 class="title">{{ title }}</h2>

    <div class="describe-box" v-if="address">
      <img
        class="title-img"
        src="@/static/svg/unclassified/Location.svg"
        alt=""
      />
      <p>{{ addressStr }}---{{ room.floorValue }}{{ room.value }}</p>
    </div>

    <div v-else class="describe-box">
      <slot name="describeL" />
      <p v-html="describe"></p>
      <slot name="describeR" />
    </div>
  </div>
</template>

<script>
/**
 * 页面标题
 * @property : {String} title 标题
 * @property : {String} describe 描述
 * @property : {String} address 是否用于显示定位  为true时 插槽全部失效 describe固定为当前选中的公寓地址
 * @slot : describeL describe左侧的内容
 * @slot : describeR describe右侧的内容
 */
import { mapGetters, mapState } from "vuex";

export default {
  name: "m-page-title",
  props: {
    title: { type: String, request: true },
    describe: { type: String, request: true },
    address: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters("apartment", ["addressStr"]),
    ...mapState("apartment", ["room"]),
  },
};
</script>

<style scoped lang="scss">
.page-title {
  display: flex;
  flex-direction: column;
  .title {
    color: #1b3862;
    font-size: 21px;
    line-height: 21px;
    font-family: Poppins-5, serif;
    font-weight: 500;
  }
  .describe-box {
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    line-height: 20px;
    color: #6c6c7a;
    .title-img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
