"use strict";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./plugins/axios";

import api from "./lib/api";
import tools from "./utils/tools";
import user from "./utils/user";

Vue.prototype.$api = api;
Vue.prototype.$tools = tools;
Vue.prototype.$user = user;

import "./lib/extension"; // 拓展原型
import "./assets/css"; // 公共样式
import "./lib/vue"; // 全局组件,过滤器

Vue.config.productionTip = false;
Number.prototype.toFixed = function (exponent) {
  return parseInt(this * Math.pow(10, exponent) + 0.5) / Math.pow(10, exponent);
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
