let authorization = "Basic UFJPUEVSVFk6UFJPUEVSVFk=";
let domainName = "84-mall.o2plus.cloud";
let homeLink = "https://login.lpmac.com.au";
let baseURL = "http://43.129.244.132:8081";
switch (process.env.NODE_ENV) {
  case "customerTest":
    authorization = "Basic UFJPUEVSVFkyOlBST1BFUlRZMg==";
    domainName = "168-mall.o2plus.cloud";
    homeLink = "https://login.lpmac.com.au";
    break;
  case "production":
    authorization = "Basic UFJPUEVSVFk6UFJPUEVSVFk=";
    domainName = "lpmac.com.au";
    homeLink = "https://login.lpmac.com.au";
    baseURL = "https://o2plus.cloud/api";
    break;
}
const api = {
  // axios config
  config: {
    baseURL,
    timeout: 30000,
    headers: { lang: "en_US" },
  },
  authorization,
  grantType: "member_password",
  domain: domainName,
  homeLink,

  // google map
  googleMap: {
    apiKey: "AIzaSyCLYUh7QXo6sAYGlmd8B6u-JknsWw_fbBU", //AIzaSyBDmxErp4mVwb9vPEvPIrZCGQEJxGr-O_I
  },

  // user 用户信息
  login: "/oauth/token", // 登录
  logOut: "/api-uaa/oauth/remove/token", // 退出登录
  signIn: "/api-property/open/member/confirmRegist/email", // 注册
  signInEmail: "/api-property/open/member/regist/email", // 注册邮件
  updatePass: "/api-member/member/updatePass", // 修改密码
  resetPwd: "/api-property/open/member/resetPwd", // 重置密码
  resetPwdEmail: "/api-property/open/resetPwdSendEmail", // 重置密码邮件
  updateEmail: "/api-property/member/updateEmail", // 修改邮箱
  updateEmailEmail: "/api-property/member/updateEmail/send", // 修改邮箱邮件
  userInfo: "/api-member/member/info", // 用用户信息查询
  updateMember: "/api-member/member/updateMember", // 用户信息修改
  vehicle: "/api-member/vehicle", // 用户车辆信息查询/设置
  getverify: "/api-property/open/member/resetPwd/verify", //验证邮箱验证码

  // apartments 公寓信息
  seekApartment: "/api-property/apartment/apartments/member", // 公寓搜索
  isHaveProcessing: "/api-order/apartmentOrder/isHaveProcessing", // 是否有订单进行中
  seekUnits: "/api-property/apartment/units/member", // 单元搜索
  informations: (id) => `/api-property/apartment/${id}/informations/member`, // 公寓信息列表
  information: (id) => `/api-labour/apartment/information/${id}/member`, // 公寓信息详情
  seekRoom: (id) => `/api-labour/apartment/${id}/rooms/member`, // 房号搜索
  notAssociated: (id) =>
    `/api-labour/apartment/${id}/rooms/member/notAssociated`, // 房号搜索(未关联房号)
  userInRoom: (id) => `/api-property/apartment/${id}/roomMember`, // 公寓房号关联用户
  getMoveFees: (id) => `/api-property/order/apartment/${id}/moveFees/member`, // 搬家费用查询
  getAdvanceHour: (apartmentId) =>
    `/api-labour/apartment/${apartmentId}/config/advanceHour`, // 查询可提前预约、修改订单时间
  setApartment: (id) => `/api-labour/apartment/${id}/areas/member`, // 公寓公共设施列表
  selTimes: "/api-labour/apartment/area/times/member", //选择时间
  getContact: (id) => `/api-labour/apartment/${id}/contact/member`, // 公寓联络信息查询
  getApartmentIno: (apartmentId) =>
    `/api-labour/apartment/${apartmentId}/photo/member`,

  //order 订单
  myBookingData: "/api-order/apartmentOrders", //订单列表
  addOrder: "/api-property/order/order", //下单
  roomMembers: "/api-labour/apartment/roomMembers", // 用户关联公寓列表
  orderInfo: (orderId) => `/api-order/apartmentOrder/${orderId}`, // 订单详情
  cancelOrder: (orderId) => `/api-property/order/order/${orderId}`, //取消订单
  isFree: (apartmentId) =>
    `/api-labour/apartment/${apartmentId}/moveFee/isFree`, //判断是否免费
  modifyOrderPart: (orderId) => `/api-order/apartmentOrder/${orderId}/member`, //修改部分订单信息
  differenceBalance: "/api-property/order/order/difference", //下单时间修改后，是否需要补差额
  updateOrder: "/api-property/order/order", //修改下单时间
  updateComment: (orderId) => `/api-order/apartmentOrder/${orderId}/comment`, //修改备注
  billingAddress: "/api-order/apartmentOrderInvoice", //查询发票地址信息

  // noticeBoard 公告
  noticeBoards: (id) => `/api-labour/apartment/${id}/noticeBoards/member`, // 公寓公告列表 - 前端
  noticeBoard: (id) => `/api-labour/apartment/noticeBoard/${id}/member`, // 公寓公告详情
  noticeBoardRead: (id) => `/api-labour/apartment/noticeBoard/${id}/read`, // 公寓公告已读
  noticeBoardReadAll: (id) => `/api-labour/apartment/noticeBoard/${id}/readAll`, // 公寓公告全部已读

  // 公共设施
  getApartmentarea: (id) => `/api-labour/apartment/area/${id}`, // 公共设施查询
  getMoving: (apartmentId) =>
    `/api-property/apartment/${apartmentId}/guideline/member`, // 搬家规则查询

  //paypal支付
  payPalPayment: "/api-property/order/payPalPayment",
  //查询Paypal支付信息
  payPalInfo: "/api-payment/payPal/clientId",
  // Payment 支付
  stripePayment: "/api-property/order/stripePayment", // stripe支付
};

export default api;
