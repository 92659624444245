<template>
  <div class="user">
    <el-popover placement="bottom" trigger="click" v-model="show">
      <div slot="reference" class="user-box flex-row">
        <div class="user-img flex-center g-pointer">
          {{ userInfo.nickName | namePortrait }}
        </div>
        <div class="user-info flex-column">
          <span class="user-name g-pointer">
            {{ userInfo.nickName | nameExt }}
          </span>
          <span class="user-status g-pointer">
            {{ userInfo.memberType | memberType }}
          </span>
        </div>
      </div>
      <div slot="default" class="user-popup flex-row">
        <div
          class="user-img g-radius-4 flex-center g-pointer"
          @click="viewProfile"
        >
          {{ userInfo.nickName | namePortrait }}
        </div>
        <div class="user-info flex-column">
          <span class="user-name">{{ userInfo.nickName | nameExt }}</span>
          <span class="user-status">
            {{ userInfo.memberType | memberType }}
          </span>
          <span class="profile g-pointer" @click="viewProfile">
            View Profile
          </span>
        </div>
        <span class="sign-out g-pointer" @click="signOut"> Sign out </span>
      </div>
    </el-popover>
  </div>
</template>

<script>
/**
 * @property null
 */
import { mapActions, mapMutations, mapState } from "vuex";
import user from "@/utils/user";
import { memberType } from "@/lib/data/tableData";
export default {
  name: "user",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  filters: {
    memberType(e) {
      if (!e && e !== 0) return "";
      return memberType.filter((item) => item.value === e)[0].label;
    },
    name(e) {
      if (!e && e !== 0) return "";
      return e.split(",").join(" ");
    },
    nameExt(e) {
      if (!e && e !== 0) return "";
      return e.split(",").splice(0, 2).join(" ");
    },
    namePortrait(e) {
      if (!e && e !== 0) return "";
      const arr = e.split(",");
      return arr[0][0].toUpperCase() + arr[1][0].toUpperCase();
    },
  },
  created() {
    user.getToken() && (this.userInfo.id || this.getUserInfo());
  },
  mounted() {
    console.log("userInfo", this.userInfo);
  },
  methods: {
    ...mapMutations("user", ["removeUser"]),
    ...mapActions("user", ["getUserInfo"]),
    ...mapMutations("bar", ["setSideBarStatus"]),
    ...mapMutations("apartment", ["setRoom", "setApartment"]),
    signOut() {
      this.$axios.post(
        this.$api.logOut,
        { access_token: user.getToken() },
        { noToken: true }
      );
      // 退出登录不做验证
      this.show = false;
      this.$notify.success("Successfully logged out");
      this.removeUser();
      this.setRoom();
      this.setApartment();
      this.$router.push("/login");
    },
    viewProfile() {
      this.show = false;
      this.setSideBarStatus(1);
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
<style lang="scss">
.user-popup {
  justify-content: space-between;
  padding: 22px;

  .user-img {
    overflow: hidden;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background-color: #8f9669;
    font-size: 36px;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .user-info {
    justify-content: center;
    margin: 0 17px;
    .user-name {
      font-size: 17px;
      font-family: "Poppins-5", serif;
      color: #262629;
    }
    .user-status {
      font-size: 13px;
      color: #6c6c7a;
    }
    .profile {
      font-size: 13px;
      color: #1b3862;
    }
  }
  .sign-out {
    margin-top: 10px;
    font-size: 13px;
    color: #1b3862;
  }
}
</style>
