import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import bar from "./modules/bar";
import user from "./modules/user";
import apartment from "./modules/apartment";
import booking from "./modules/booking";

export default new Vuex.Store({
  modules: {
    bar, // 侧边栏/丁兰
    user, // 个人信息
    apartment, // 当前选中的公寓信息
    booking, // 当前选中的预定信息
  },
});
