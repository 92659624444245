<template>
  <div class="side-bar flex-column">
    <m-logo class="log" />
    <div
      class="flex-1 ml-5 flex-column"
      :class="{ profile: sideBarStatus === 1 }"
    >
      <div
        class="items flex-0 flex-column"
        :class="{ show: itemsShow(index), check: presentDataIndex === index }"
        v-for="(item, index) in presentData"
        :key="index"
      >
        <div class="line" v-if="index"></div>
        <div class="node flex-row">
          <div class="circle"></div>
          <span class="label g-pointer" @click="toClick(item, index)">
            {{ item.label }}
          </span>
        </div>
      </div>
      <div
        v-if="sideBarStatus !== -1"
        class="line-end"
        :class="{ show: endLineShow }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "SideBar",
  props: {},
  computed: {
    ...mapState("bar", ["sideBarStatus"]),
    ...mapGetters("bar", ["presentData"]),
    // 计算当前页的index
    presentDataIndex() {
      if (!this.presentData) return false;
      for (const i in this.presentData) {
        // noinspection JSUnfilteredForInLoop
        if (this.presentData[i].path === this.$route.path) {
          // noinspection JSUnfilteredForInLoop
          return Number(i);
        }
      }
      return -1;
    },
    // 每个节点的样式
    itemsShow() {
      return (index) => this.presentDataIndex >= index;
    },
    // 最后一根线的样式
    endLineShow() {
      if (!this.presentData) return false;
      return this.presentDataIndex >= this.presentData.length - 1;
    },
  },
  methods: {
    // 点击节点 - 跳转 目前只有个人信息相关模块有需求
    toClick(e) {
      this.sideBarStatus === 1 && this.$router.replace(e.path);
    },
    /* toClick(e, index) {
      if (e.path === this.$route.path) return false;
      if (this.sideBarStatus === 1) {
        this.$router.replace(e.path);
      } else {
        if (!e.check) return false;
        if (!this.itemsShow(index)) return false;
        this.$router.push(e.path);
      }
    },*/
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
