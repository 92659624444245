<template>
  <transition name="fade">
    <div
      v-show="dialogShow"
      class="dialog-mask"
      @click="mask || $emit('change', false)"
    >
      <div class="dialog-content">
        <el-image
          v-if="!unBack"
          class="close g-pointer"
          :src="require('@/static/icon/Close.png')"
          @click="hide"
        ></el-image>
        <div class="content-l">
          <div class="txt-box">
            <span class="address-txt">{{ address }}</span>
            <span class="title-txt">{{ title }}</span>
            <span class="content-txt">{{ content }}</span>
          </div>
          <div class="btn-box">
            <el-button
              v-if="!confirmHide"
              type="primary"
              plain
              @click.stop="$emit('confirm')"
            >
              {{ confirmTxt }}
            </el-button>
            <el-button
              v-if="!cancelHide"
              type="primary"
              plain
              @click.stop="$emit('cancel')"
            >
              {{ cancelTxt }}
            </el-button>
          </div>
        </div>
        <div class="content-r">
          <el-image class="img" :src="image" fit="cover" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * m-dialog 自定义弹框
 * @description ---
 *
 * @property : {Boolean}  dialogShow (v-model)  显示/隐藏弹框
 * @property : {Boolean}  mask 是否取消遮盖层关闭弹框 默认false
 * @property : {Boolean}  unBack 隐藏关闭按钮 默认false
 * content  show:
 * @property : {String | Image}  image 图片地址
 * @property : {String}  address 地址
 * @property : {String}  title 标题
 * @property : {String}  content 内容
 * btn style:
 * @property : {Boolean}  confirmHide 隐藏确认按钮 默认false
 * @property : {Boolean}  cancelHide 隐藏取消按钮 默认false
 * @property : {String}  confirmTxt 确认按钮文字 默认confirm
 * @property : {String}  cancelTxt 取消按钮文字 默认cancel
 * event:
 * @event : {Function}  confirm 确认按钮事件
 * @event : {Function}  cancel 取消按钮事件
 * methods:
 * @method : show : 显示弹框
 * @method : hide : 隐藏弹框
 */
export default {
  name: "m-dialog",
  components: {},
  model: {
    prop: "dialogShow",
    event: "change",
  },
  props: {
    dialogShow: { type: Boolean },
    mask: { type: Boolean, default: false },
    unBack: { type: Boolean, default: false },
    image: { type: String, default: require("@/static/image/exhibition.png") },
    address: { type: String },
    title: { type: String },
    content: { type: String },
    confirmHide: { type: Boolean, default: false },
    cancelHide: { type: Boolean, default: false },
    confirmTxt: { type: String, default: "confirm" },
    cancelTxt: { type: String, default: "cancel" },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    show() {
      this.$emit("change", true);
    },
    hide() {
      this.$emit("change", false);
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
