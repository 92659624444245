export default {
  namespaced: true,
  state: () => ({
    bookdata: {
      type: null,
      facility: {},
      date: "",
      time: "",
    },
    modifyBookingData: {},
  }),
  getters: {},
  mutations: {
    setType(state, obj = 0) {
      //保存预定类型：Move In:0 Move out:1 Communal Facility:2
      state.bookdata.type = obj;
    },
    setFacility(state, obj = {}) {
      //保存公共设备
      state.bookdata.facility = obj;
    },
    setDate(state, obj = "") {
      //保存日期
      state.bookdata.date = obj;
    },
    setTime(state, obj = "") {
      //保存时间
      state.bookdata.time = obj;
    },
    setReduction(state) {
      state.bookdata = {
        type: null,
        facility: {},
        date: "",
        time: "",
      };
    },
    setModifyBookingData(state, obj = {}) {
      //保存修改订单信息
      state.modifyBookingData = obj;
    },
  },
  actions: {},
};
