/**
 * 常用全局统一的键值对
 */

module.exports = {
  // 用户身份
  memberType: [
    { value: 0, label: "Resident/Owner" },
    { value: 1, label: "Agent" },
    // { value: 2, label: "Tenant" },
  ],
};
