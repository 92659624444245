<template>
  <div class="m-back" @click="backClick">
    <i class="el-icon-arrow-left back-icon" />
    <el-link class="back-txt">Back</el-link>
  </div>
</template>

<script>
/**
 * @property : {Boolean}  unBack 是否拦截返回事件
 * @event : {Function}  click 点击事件
 */
import { mapMutations } from "vuex";
export default {
  name: "m-back",
  props: {
    // 拦截返回事件
    unBack: {
      type: Boolean,
      default: false,
    },
    //回退左部菜单展示
    barStatus: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapMutations("bar", ["setSideBarStatus"]),
    backClick() {
      if (this.barStatus) {
        this.setSideBarStatus(parseInt(this.barStatus));
      }
      this.$emit("click");
      this.unBack || this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.m-back {
  user-select: none;
  display: flex;
  align-items: center;
  .back-icon {
    font-size: 22px;
    font-weight: 700;
    color: #445f85;
    &:hover {
      color: #1b3862;
    }
  }
  .back-txt {
    font-size: 17px;
    font-family: "Poppins-5", serif;
    color: #445f85;
    &:hover {
      color: #1b3862;
    }
  }
}
</style>
