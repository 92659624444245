"use strict";
import Vue from "vue";
import router from "@/router";
import axios from "axios";
import { Notification } from "element-ui";
import api from "@/lib/api";
import user from "@/utils/user";

const debug = true;

function errMsg(message) {
  Notification({ type: "error", title: "Error", message });
}

function notifyMessage(
  obj = { type: "info", title: "information", message: "" }
) {
  Notification(obj);
}

const _axios = axios.create(api.config);

_axios.interceptors.request.use(
  (config) => {
    // 传递token
    const token = user.getToken();
    if (!config["noToken"] && token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    debug && console.log("-----------------------------");
    debug && console.log("请求:");
    debug && console.log(config);
    debug && console.log("-----------------------------");
    return config;
  },
  (error) => {
    debug && console.log("-----------------------------");
    debug && console.log("请求错误:");
    debug && console.log(error);
    debug && console.log("-----------------------------");
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    debug && console.log("-----------------------------");
    debug && console.log("响应");
    debug && console.log(response);
    debug && console.log("-----------------------------");

    // 数据错误
    if (response.data.code && response.data.code !== "1000") {
      switch (response.data.code) {
        case "ORC500": //调用订单中心熔断
          notifyMessage({
            type: "info",
            title: "information",
            message: "Restarting server, please try again later.",
          });
          break;
        case "PS1005":
        case "LC1021":
          errMsg(
            "No time slot available on this date. Please select another date."
          );
          break;
        case "PAYC1005":
        case "KS1007":
          errMsg("Payment failed.");
          break;
        case "PS1008":
          errMsg(
            "You have already booked the service, please do not book again."
          );
          break;
        case "LC1036":
          errMsg(
            "The time limit of your daily booking has been reached. You cannot make any more booking on the same day."
          );
          break;
        default:
          errMsg(response.data.msg);
          break;
      }
      debug && console.log("-----------------------------");
      debug && console.log("数据错误");
      debug && console.log(response.data);
      debug && console.log("-----------------------------");
    }
    return response.data;
  },
  (error) => {
    debug && console.log("-----------------------------");
    debug && console.log("响应错误");
    debug && console.log(error);
    debug && console.log(error.response);
    debug && console.log("-----------------------------");

    const code = error.response.status;
    let message = error.response.data.msg || error.response.data.resp_msg || "";
    switch (code) {
      case 401:
        user.removeToken();
        router.push("/login");
        break;
      case 500:
      case 503:
        notifyMessage({
          type: "info",
          title: "information",
          message: "Restarting server, please try again later.",
        });
        break;
      default:
        switch (message) {
          case "账号未注册！":
            message = "The email is not registered, please register first！";
            break;
          case "账号或密码错误！":
            message = "Incorrect password, please try again！";
            break;
        }
        notifyMessage({ type: "error", title: "Error", message });
        break;
    }

    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};
Vue.use(Plugin);

// export default Plugin;
export default _axios;
