import axios from "@/plugins/axios";
import api from "@/lib/api";
import user from "@/utils/user";

export default {
  namespaced: true,
  state: () => ({
    userInfo: {},
  }),
  getters: {},
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data.member;
    },
    removeUser(state) {
      state.userInfo = {};
      user.removeToken();
    },
  },
  actions: {
    getUserInfo(context) {
      axios.get(api.userInfo).then((res) => {
        res.code === "1000" && context.commit("setUserInfo", res.data);
      });
    },
  },
};
