function getMonth(d) {
  let month = d.getMonth();
  if (month === 12) month = 1;
  else month += 1;
  return month;
}

/**
 * 格式化时间
 * @param formatStr {String} 时间格式 默认值: yyyy-mm-dd h:i:s
 * @returns {String}
 */
function formatTime(formatStr = "yyyy-mm-dd h:i:s") {
  return formatStr.replace(/[a-z]+/g, (res) => {
    switch (res.toLowerCase()) {
      case "yyyy":
        return this.getFullYear();
      case "yy":
        return this.getFullYear().toString().substr(2, 2);
      case "mm":
        return getMonth(this).$zeroIze();
      case "m":
        return getMonth(this);
      case "dd":
        return this.getDate().$zeroIze();
      case "d":
        return this.getDate();
      case "h":
        return this.getHours().$zeroIze();
      case "i":
        return this.getMinutes().$zeroIze();
      case "s":
        return this.getSeconds().$zeroIze();
    }
  });
}

Date.prototype.$formatTime = formatTime;
