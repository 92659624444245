import $ from "store";

const tokenKey = "JIASASDAIHEOASDHJKWQE";

export default {
  setToken(e) {
    $.set(tokenKey, e);
  },
  getToken() {
    return $.get(tokenKey);
  },
  removeToken() {
    $.remove(tokenKey);
  },
};
